import React from 'react';
import {
  CodeSnippet,
  Link,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, FormSection, Input } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/form-section.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, FormSection: FormSection, Input: Input }}
        code={snippet}
        platform="react"
        gitHubLink="forms/form-section"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="sectionText" types={['string']}>
            <Text>
              The text that appears above the section of form components.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-helpers/space#Margin%20Classes">
                uniform-ui-css space classes.
              </Link>
            </Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique-enough string that appears as a data attribute in the
              rendered code and serves as a hook for automated tests.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
